import React from 'react';
import { useQuery } from '@apollo/client';

import LoadingIndicator from '../LoadingIndicator';
import { GET_ORG_ID, GET_STORE } from '../../constants/queries';

import OrderTotal from './OrderTotal';
import { computeCosts } from './OrderSummary.tools';
import PropTypes from 'prop-types';
import Honeybadger from '@honeybadger-io/js';

const OrderShippingTotal = ({ subtotal = 0, cartItems = [], primaryColor }) => {
  const {
    data: { orgId },
    loading: orgIdLoading,
    error: errorId,
  } = useQuery(GET_ORG_ID);

  if (errorId) {
    const errorMessage = `[GET_ORG_ID query]: Message: ${errorId}`;
    Honeybadger.notify(errorMessage);
    console.error(errorId, 'error with GET_ORG_ID');
  }

  const { data, loading, error } = useQuery(GET_STORE, { variables: { orgId: orgId } });

  if (error) {
    const errorMessage = `[GET_STORE query]: Message: ${error}, orgId: ${orgId}`;
    Honeybadger.notify(errorMessage);
    console.error(error, 'error with GET_STORE');
  }

  if (orgIdLoading || loading) return <LoadingIndicator color={primaryColor} />;

  if (data?.organization && cartItems.length > 0) {
    const { total, shipping } = computeCosts({ data, cartItems, subtotal });
    return <OrderTotal shipping={shipping} total={total} />;
  }

  return <OrderTotal shipping={0} total={subtotal} />;
};

OrderShippingTotal.propTypes = {
  subtotal: PropTypes.number,
  cartItems: PropTypes.array,
  primaryColor: PropTypes.string,
};

export default OrderShippingTotal;
