import React from 'react';
import PropTypes, { string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Breakpoint } from 'react-socks';

import CartButton from './CartButton';
import StorefrontLogo from './StorefrontLogo';
import NavBarMobile from './nav/mobile.component';
import NavBarDesktop from './nav/desktop.component';
import '../assets/stylesheets/navbar.css';
const PARAM_COLLECTION = 'collection';

const StorefrontNav = ({ primaryColor, collections, history, location, data, selectedCollection, orgId, cartId }) => {
  const onChangeCollection = event => {
    const collectionSlug = event.target.dataset.collection || '';
    const params = new URLSearchParams(location.search);
    params.set(PARAM_COLLECTION, collectionSlug);

    history.push({
      pathname: '/',
      search: '?' + params.toString(),
    });

    const navMain = window.$('.navbar-collapse');
    navMain.collapse('hide');
  };

  const noCollections = !collections || collections.length === 0;

  return (
    <div className="storefront-nav d-flex justify-content-between pb-4 pt-3">
      <div className="d-flex">
        <StorefrontLogo data={data} />

        {noCollections ? null : (
          <Breakpoint large up className="navbar-desktop-container">
            <NavBarDesktop
              collections={collections}
              onChangeCollection={onChangeCollection}
              primaryColor={primaryColor}
              selectedCollection={selectedCollection}
            />
          </Breakpoint>
        )}
      </div>

      <div className="d-flex">
        <CartButton
          className="rv-cta-button"
          nav={true}
          primaryColor={primaryColor}
          text={<span className="d-none d-sm-inline">My Cart</span>}
          orgId={orgId}
          cartId={cartId}
        />

        {noCollections ? null : (
          <Breakpoint medium down>
            <NavBarMobile
              collections={collections}
              onChangeCollection={onChangeCollection}
              primaryColor={primaryColor}
              selectedCollection={selectedCollection}
            />
          </Breakpoint>
        )}
      </div>
    </div>
  );
};

StorefrontNav.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  collections: PropTypes.array,
  selectedCollection: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
};

StorefrontNav.defaultProps = {
  collections: [],
};

export default withRouter(StorefrontNav);
