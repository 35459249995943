import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_CART } from '../constants/queries';
import { CartItem } from './CartItem';
import LoadingIndicator from './LoadingIndicator';
import PropTypes from 'prop-types';
import Honeybadger from '@honeybadger-io/js';

export const CartItems = ({ primaryColor, orgId, cartId }) => {
  const { data, loading, error } = useQuery(GET_CART, {
    variables: { orgId: orgId, cartId: cartId },
    fetchPolicy: 'network-only',
  });

  if (error) {
    const errorMessage = `[GET_CART query]: Message: ${error}, orgId: ${orgId}, cartId: ${cartId}`;
    console.error(errorMessage);
    Honeybadger.notify(errorMessage);
  }

  if (loading) return <LoadingIndicator />;

  const renderEmpty = () => {
    return (
      <p className="empty-cart text-center col-md-8">
        You don&apos;t have any items in your cart, start shopping! <Link to="/">Go to Storefront</Link>
      </p>
    );
  };

  if (!cartId) return renderEmpty();

  if (loading) {
    return (
      <div className="col-12 col-md-8">
        <LoadingIndicator color={primaryColor} />
      </div>
    );
  }

  const cart = data?.organization?.store?.cart;
  if (cart && cart?.cart_items?.length > 0) {
    return (
      <div className="cart-items col-12 col-md-8">
        {cart.cart_items.map(item => (
          <CartItem key={item.id} item={item} orgId={orgId} cartId={cartId} />
        ))}
      </div>
    );
  } else {
    return renderEmpty();
  }
};

CartItems.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
};
