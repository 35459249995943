import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { GET_STORE } from '../constants/queries';
import { UPDATE_CART_ORIGIN_DETAIL } from '../constants/mutations.js';
import { getCookie } from '../helpers/cookieHelpers';
import LoadingIndicator from './LoadingIndicator';
import Honeybadger from '@honeybadger-io/js';

const CheckoutButton = ({ cartItems, primaryColor, orgId, cartId }) => {
  const { data, loading, error } = useQuery(GET_STORE, { variables: { orgId: orgId } });

  if (error) {
    const errorMessage = `[GET_STORE query]: Message: ${error}, cartId: ${cartId}, orgId: ${orgId}`;
    Honeybadger.notify(errorMessage);
    console.error(error);
  }

  const [updateCartOriginDetail] = useMutation(UPDATE_CART_ORIGIN_DETAIL);

  useEffect(() => {
    const sourceCode = getCookie('sourceCode');
    if (sourceCode) {
      try {
        updateCartOriginDetail({
          variables: {
            cartId: cartId,
            originDetailAttributes: { sourceCode: sourceCode },
          },
        });
      } catch (e) {
        const errorMessage = `[UPDATE_CART_ORIGIN_DETAIL mutation]: Message: ${e}, cartId: ${cartId}, sourceCode: ${sourceCode}`;
        Honeybadger.notify(errorMessage);
        console.error(e);
      }
    }
  }, [cartId, updateCartOriginDetail]);

  if (error) return null;
  if (loading) return <LoadingIndicator color={primaryColor} />;

  return (
    <span className="checkout-button text-center">
      <a
        href={`${
          data.organization.win_red_store
            ? data.organization.store.settings.win_red_checkout_url
            : data.organization.store.settings.checkout_url
        }/${cartId}`}
      >
        <Button
          className="rv-cta-button"
          disabled={!cartItems || (cartItems && cartItems.length === 0)}
          style={{ backgroundColor: data.organization.store.settings.primary_color }}
        >
          Proceed to Checkout <i className="fal fa-chevron-circle-right" />
        </Button>
      </a>
    </span>
  );
};

export default CheckoutButton;

CheckoutButton.propTypes = {
  cartItems: PropTypes.array.isRequired,
  orgId: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
};
