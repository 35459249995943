import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../assets/stylesheets/imageCarousel.css';
import EMPTY_IMAGE from '../assets/images/empty.jpg';

const ImageCarousel = ({ images, selectedItem }) => {
  const renderImage = src => {
    return (
      <div key={src} className="image-container mx-auto">
        <img className="product-image" src={src} alt="" />
      </div>
    );
  };

  return (
    <Carousel
      className="product-image-carousel text-center"
      showStatus={false}
      showIndicators={false}
      showArrows={false}
      selectedItem={selectedItem}
      showThumbs={images && images.length > 1}
    >
      {images.length > 0 ? images.map(image => renderImage(image.image_url)) : renderImage(EMPTY_IMAGE)}
    </Carousel>
  );
};

export default ImageCarousel;

ImageCarousel.propTypes = {
  images: PropTypes.array.isRequired,
};
