import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import { setUtms, setCookie, getCookies } from '../helpers/cookieHelpers';

import StorefrontNav from './StorefrontNav';
import GetProduct from '../components/GetProduct';
import StorefrontFooter from './StorefrontFooter';
import ShoppingCart from './ShoppingCart';
import AddedToCart from './AddedToCart';
import StatusText from './StatusText';
import Home from './Home';

import { getSelectedCollectionBySlug } from '../helpers/collections';
import '../assets/stylesheets/index.css';
import { useMutation } from '@apollo/client';
import { CREATE_CART } from '../constants/mutations.js';
import { CHECK_CART_ID } from '../constants/queries.js';
import { client } from '../apolloSetup.js';
import LoadingIndicator from './LoadingIndicator.jsx';
import Honeybadger from '@honeybadger-io/js';

const PARAM_COLLECTION = 'collection';

const isNotValidCartId = id => {
  if (id === null || id === undefined) return true;
  if (typeof id !== 'string') return true;
  if (id.length < 1) return true;
  return false;
};

function Storefront({ location, data, settings, cartId, collections, orgId, productSlug }) {
  const [hasMadeNewCart, setHasMadeNewCart] = useState(false);
  const [cartIdState, setCartIdState] = useState(cartId);

  const params = queryString.parse(location.search);

  const [makeNewCart, { loading: loadingNewCart, error: errorMakeNewCart }] = useMutation(CREATE_CART);

  const onMakeNewCart = async () => {
    try {
      const originDetails = getCookies();
      const variables = {
        cartAttributes: {
          items: [],
          merchStoreId: data.organization.store.id,
        },
        originDetailAttributes: originDetails,
      };
      const results = await makeNewCart({ variables });
      let errors = results.data?.createCart?.errors;
      if (errors && errors.length > 0) {
        Honeybadger.notify(errors);
        console.error(errors);
      }
      return results.data?.createCart?.cart?.revv_uid;
    } catch (error) {
      const errorMessage = `[CREATE_CART mutation]: Message: ${error}, merchStoreId: ${data?.organization?.store?.id}`;
      Honeybadger.notify(errorMessage);
      console.error(error);
    }
  };

  useEffect(() => {
    if (isNotValidCartId(cartIdState) && !hasMadeNewCart) {
      onMakeNewCart().then(newCartId => {
        setCartIdState(newCartId);
        client.writeQuery({ query: CHECK_CART_ID, data: { cartId: newCartId } });
        setHasMadeNewCart(true);
      });
    }
  }, [makeNewCart, cartId, hasMadeNewCart, cartIdState]);

  const getCollectionFromParams = () => {
    const params = new URLSearchParams(location.search);
    const collectionParam = params.get(PARAM_COLLECTION);
    const collection = getSelectedCollectionBySlug(collections, collectionParam);
    return params.has(PARAM_COLLECTION) && collection ? collection : {};
  };

  setUtms(params);
  if (params['sc']) setCookie('sourceCode', params['sc']);
  setCookie('sourceUrl', window.location.href);
  setCookie('userAgent', window.navigator.userAgent);

  const selectedCollection = getCollectionFromParams();

  if (errorMakeNewCart) {
    const errorMessage = `[CREATE_CART mutation]: Message: ${errorMakeNewCart}, orgId: ${orgId}, cartId: ${cartId}`;
    Honeybadger.notify(errorMessage);
    console.error(errorMessage);
  }

  if (!cartIdState || loadingNewCart || isNotValidCartId(cartIdState))
    return <LoadingIndicator color={data.organization.store.settings.primary_color} />;

  return (
    <div style={{ backgroundColor: data.organization.store.settings.background_color }}>
      <div id="storefront" className="container">
        <StorefrontNav
          primaryColor={data.organization.store.settings.primary_color}
          orgId={orgId}
          data={data}
          collections={collections}
          selectedCollection={selectedCollection}
          cartId={cartIdState}
        />
        <StatusText location={location} />
        <AddedToCart
          location={location}
          primaryColor={data.organization.store.settings.primary_color}
          orgId={orgId}
          cartId={cartIdState}
        />
        <Switch>
          <Route
            path="/cart"
            render={props => (
              <ShoppingCart data={data} primaryColor={settings.primary_color} orgId={orgId} cartId={cartIdState} />
            )}
          />
          <Route
            path="/:productSlug/details"
            render={props => (
              <GetProduct
                settings={data.organization.store.settings}
                cartId={cartIdState}
                storeId={data.organization.store.id}
                data={data}
                orgId={orgId}
                productSlug={productSlug}
                {...props}
              />
            )}
          />
          <Route
            path="/"
            render={() => (
              <Home
                settings={data.organization.store.settings}
                data={data}
                collections={collections}
                selectedCollection={selectedCollection}
              />
            )}
          />
        </Switch>
      </div>
      <StorefrontFooter settings={data.organization.store.settings} orgId={orgId} data={data} />
    </div>
  );
}

Storefront.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  cartId: PropTypes.string,
  storeId: PropTypes.number.isRequired,
  collections: PropTypes.array,
  orgId: PropTypes.string.isRequired,
  productSlug: PropTypes.string.isRequired,
};

Storefront.defaultProps = {
  collections: [],
  cartId: '',
};

export default Storefront;
