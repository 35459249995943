// Apollo Client (GraphQL)
import { ApolloClient, InMemoryCache, defaultDataIdFromObject, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { persistCache } from 'apollo3-cache-persist';
import Honeybadger from '@honeybadger-io/js';
import { v5 as uuidv5 } from 'uuid';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {},
    },
  },
  dataIdFromObject: obj => {
    if (obj.revv_uid) return obj.revv_uid;
    else {
      switch (obj.__typename) {
        case 'ProductImage':
          return obj.image_url;
        default:
          return defaultDataIdFromObject(obj);
      }
    }
  },
  cacheRedirects: {
    Query: {
      Product: (_, { revv_uid }, { getCacheKey }) => getCacheKey({ __typename: 'Product', id: revv_uid }),
      CartItem: (_, { id }, { getCacheKey }) => getCacheKey({ __typename: 'CartItem', id: id }),
      Organization: (_, { revv_uid }, { getCacheKey }) => getCacheKey({ __typename: 'Organization', id: revv_uid }),
    },
  },
}).restore(window.__APOLLO_STATE__);

// create a unique key for the cache based on the url
let regexp = /storefront\//;
const end = window.location.href.search(regexp);
const baseUrl = window.location.href.slice(0, end);
let uid = uuidv5(baseUrl, uuidv5.URL);

try {
  persistCache({ cache, storage: window.localStorage, key: `${uid}` });
} catch (error) {
  alert(
    'Looks like you have cookies and site data blocked. Please enable cookies and site data to ensure proper functioning of this site.'
  );
  throw new Error('Cookies and site data blocked.');
}

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API || `${window.location.protocol}//${window.location.hostname}/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      Honeybadger.notify(error);
      return console.error(error);
    });
  }
  if (networkError) {
    const error = `[Network error]: ${networkError}`;
    console.error(error);
    Honeybadger.notify(error);
  }
});

const link = ApolloLink.from([errorLink, httpLink]);

export const client = new ApolloClient({
  cache,
  link,
  connectToDevTools: process.env.NODE_ENV !== 'production',
});
