import React from 'react';
import { Link } from 'react-router-dom';

import revvLogo from '../assets/images/revv_modern@2x.png';
import winRedLogo from '../assets/images/shopping-bag 2.svg';
import revvFavicon from '../assets/images/favicon.ico';
import winRedFavicon from '../assets/images/win-red-mark-small.png';

import Favicon from 'react-favicon';

const StorefrontLogo = ({ data }) => {
  const logoCalloutSrc = data => {
    if (data.organization.win_red_store) {
      return winRedLogo;
    } else {
      return revvLogo;
    }
  };

  const faviconCheck = data => {
    if (data.organization.win_red_store) {
      return winRedFavicon;
    } else {
      return revvFavicon;
    }
  };

  return (
    <React.Fragment>
      <Favicon url={faviconCheck(data)} />
      <Link to="/" className="storefront-link d-flex justify-content-center">
        <div className="storefront-logo">
          
            <img
              src={data.organization.store.settings.logo_url || logoCalloutSrc(data)}
              alt="Logo"
              
              className="storefront-logo-image"
            />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default StorefrontLogo;
