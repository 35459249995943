import React from 'react';
import PropTypes from 'prop-types';

function NavBarDesktop({ collections, onChangeCollection, selectedCollection, primaryColor }) {

  return (
    <nav className="navbar navbar-expand navbar-desktop navbar-light">
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            <a
              className="nav-link"
              href="#"
              data-collection=""
              onClick={onChangeCollection}
              style={{ color: selectedCollection.revv_uid === '' ? primaryColor : null }}
            >
              All
            </a>
          </li>
          {collections.map(collection => {
            const hasChildren = collection.child_collections && collection.child_collections.length > 0;

            if (hasChildren) {
              return (
                <li className="nav-item dropdown" key={collection.revv_uid}>
                  <a
                    className="nav-link dropdown-toggle collection"
                    href="#"
                    id={'navbarDropdownDesktop' + collection.revv_uid}
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="false"
                    aria-expanded="false"
                    style={{ color: selectedCollection.revv_uid === collection.revv_uid ? primaryColor : null }}
                  >
                    {collection.internal_name}
                  </a>
                  <div className="dropdown-menu" aria-labelledby={'navbarDropdownDesktop' + collection.revv_uid}>
                    {/* show collection itself AND its children in dropdown */}
                    {[collection, ...collection.child_collections].map(child => {
                      return (
                        <a
                          key={child.revv_uid}
                          className="dropdown-item collection"
                          data-collection={child.slug}
                          href="#"
                          onClick={onChangeCollection}
                          style={{ color: selectedCollection.revv_uid === child.revv_uid ? primaryColor : null }}
                        >
                          {child.internal_name}
                        </a>
                      );
                    })}
                  </div>
                </li>
              );
            } else {
              return (
                <li className="nav-item" key={collection.revv_uid}>
                  <a
                    className="nav-link collection"
                    href="#"
                    data-collection={collection.slug}
                    onClick={onChangeCollection}
                    style={{ color: selectedCollection.revv_uid === collection.revv_uid ? primaryColor : null }}
                  >
                    {collection.internal_name}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </nav>
  );
}

NavBarDesktop.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  collections: PropTypes.array.isRequired,
  onChangeCollection: PropTypes.func.isRequired,
  selectedCollection: PropTypes.object.isRequired,
};

export default NavBarDesktop;
