import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import CheckoutButton from '../CheckoutButton';

const OrderCosts = ({ total = 0, subtotal = 0, cartItems = [], primaryColor, children, orgId, cartId }) => {
  return (
    <div className="col-12 col-md-4">
      <Card className="border-0">
        <CardBody className="order-summary p-0 pb-3 text-align-center">
          <CardTitle className="card-title px-3 py-3">Order Summary</CardTitle>
          <div className="subtotal px-3 py-2">
            Subtotal <span className="pull-right strong">${subtotal}</span>
          </div>
          {total}
          <div className="text-center">
            <CheckoutButton cartItems={cartItems} primaryColor={primaryColor} orgId={orgId} cartId={cartId} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default OrderCosts;
