import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { client } from '../apolloSetup.js';

import { PRODUCTS_QUERY, PRODUCTS_QUERY_PAGED, GET_ORG_ID } from '../constants/queries';
import ProductTile from './ProductTile';
import LoadingIndicator from './LoadingIndicator';
import Pagination from './Pagination';
import Honeybadger from '@honeybadger-io/js';

const LIST_PAGE_SIZE = 12;
const PARAM_PAGE = 'page';

const ProductsList = ({ settings, selectedCollection, location, history }) => {
  const getPageFromParams = () => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get(PARAM_PAGE), 10);
    return params.has(PARAM_PAGE) && !isNaN(page) ? page : 1;
  };
  const [currentPage, setCurrentPage] = useState(getPageFromParams());

  useEffect(() => {
    const page = getPageFromParams();
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [location.search, currentPage]);

  const renderEmpty = () => {
    return <div className="empty-products text-center">There are no products available at this time.</div>;
  };

  const renderProducts = products => {
    products = (products && products.results) || products || [];

    if (products.length === 0) return renderEmpty();

    return products.map(product => {
      return <ProductTile key={product.product.friendly_id} product={product.product} />;
    });
  };

  const handleSetPage = newPage => {
    let params = new URLSearchParams(location.search);
    params.set(PARAM_PAGE, newPage);

    history.push({
      pathname: '/',
      search: '?' + params.toString(),
    });
    setCurrentPage(newPage);
  };

  const query = settings.enable_product_pagination ? PRODUCTS_QUERY_PAGED : PRODUCTS_QUERY;
  const { orgId } = client.readQuery({ query: GET_ORG_ID }) || {};
  let data, loading, error;
  try {
    ({ data, loading, error } = useQuery(query, {
      variables: {
        revv_uid: orgId,
        page: currentPage,
        collection: selectedCollection && selectedCollection.revv_uid,
        limit: LIST_PAGE_SIZE,
      },
      fetchPolicy: 'network-only',
    }));
    if (error) {
      const errorMessage = `[PRODUCTS_QUERY query]: Message: ${error}, orgId: ${orgId}, collection: ${selectedCollection?.revv_uid} page: ${currentPage}`;
      Honeybadger.notify(errorMessage);
      console.error(error);
    }
  } catch (e) {
    const errorMessage = `[PRODUCTS_QUERY query]: Message: ${e}, orgId: ${orgId}, collection: ${selectedCollection?.revv_uid} page: ${currentPage}`;
    Honeybadger.notify(errorMessage);
    console.error(e);
  }

  const listTitle = selectedCollection && selectedCollection.internal_name;

  return (
    <div className="products-list">
      <h2 className="featured-products-title text-center mb-5">{listTitle}</h2>

      {loading ? (
        <LoadingIndicator color={settings.primary_color} />
      ) : (
        <div>
          <div className="product-row row d-flex justify-content-start">
            {renderProducts(data?.organization?.store?.store_items)}
          </div>

          {settings.enable_product_pagination && (
            <Pagination
              setPage={handleSetPage}
              currentPage={currentPage}
              limit={LIST_PAGE_SIZE}
              pageCount={data?.organization?.store.store_items.page_count}
              totalCount={data?.organization?.store.store_items.total_count}
              primaryColor={settings.primary_color}
            />
          )}
        </div>
      )}
    </div>
  );
};

ProductsList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  selectedCollection: PropTypes.object.isRequired,
};

export default withRouter(ProductsList);
