import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { GET_CART } from '../constants/queries';
import Honeybadger from '@honeybadger-io/js';
import LoadingIndicator from './LoadingIndicator';
import { useQuery } from '@apollo/client';

const CartButton = ({ nav, text, className, primaryColor, orgId, cartId }) => {
  const { data, loading, error } = useQuery(GET_CART, {
    variables: { orgId: orgId, cartId: cartId },
    fetchPolicy: 'network-only',
  });

  if (error) {
    const errorMessage = `[GET_CART query]: Message: ${error}, orgId: ${orgId}, cartId: ${cartId}, strigifiedFields: ${JSON.stringify(
      { orgId: orgId, cartId: cartId }
    )}`;
    console.error(errorMessage);
    Honeybadger.notify(errorMessage);
  }

  if (loading) return <LoadingIndicator color={primaryColor} />;

  const renderButtonContent = (nav, text, data) => {
    let itemCount = 0;
    if (data?.organization?.store?.cart) {
      let items = data.organization.store.cart.cart_items;
      if (items.length > 0) {
        itemCount = items.reduce((acc, item) => {
          return acc + item.quantity;
        }, 0);
      }
    }
    const icon = nav ? <i className="fas fa-shopping-cart mr-2" /> : null;
    return (
      <div>
        {icon} {text} ({itemCount})
      </div>
    );
  };

  return (
    <span className="cart-button">
      <Link to="/cart">
        <Button style={{ backgroundColor: primaryColor }} className={`${className}`}>
          {renderButtonContent(nav, text, data)}
        </Button>
      </Link>
    </span>
  );
};

CartButton.propTypes = {
  nav: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  className: PropTypes.string.isRequired,
  primaryColor: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
};

export default CartButton;
