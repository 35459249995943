import React from 'react';
import { useQuery } from '@apollo/client';

import LoadingIndicator from '../LoadingIndicator';
import { GET_CART } from '../../constants/queries';
import { formatPrice } from '../../helpers/presenters';

import OrderShippingTotal from './OrderShippingTotal';
import OrderCosts from './OrderCosts';
import PropTypes from 'prop-types';
import Honeybadger from '@honeybadger-io/js';

const OrderSummary = ({ primaryColor, orgId, cartId }) => {
  const { data, loading, error } = useQuery(GET_CART, {
    variables: { orgId: orgId, cartId: cartId },
    skip: !cartId,
  });
  if (error) {
    const errorMessage = `[GET_CART query]: Message: ${error}, cartId: ${cartId}, orgId: ${orgId}`;
    Honeybadger.notify(errorMessage);
    console.error(error);
  }

  if (loading) return <LoadingIndicator color={primaryColor} />;

  const cart = data?.organization?.store?.cart;
  const cartItems = cart?.cart_items || [];
  const subtotal = cartItems?.reduce((acc, item) => {
    return acc + item.variant?.variant_price * item.quantity;
  }, 0);

  return (
    <OrderCosts
      total={<OrderShippingTotal subtotal={subtotal} cartItems={cartItems} primaryColor={primaryColor} />}
      cartItems={cartItems}
      primaryColor={primaryColor}
      subtotal={formatPrice(subtotal)}
      orgId={orgId}
      cartId={cartId}
    />
  );
};

OrderSummary.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
};

export default OrderSummary;
