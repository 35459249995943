import React from 'react';
import PropTypes from 'prop-types';
import StorefrontLogo from "./StorefrontLogo";
import poweredByRevvLogo from "../assets/images/revv_modern.svg";
import poweredByWinRedLogo from "../assets/images/win-red-full-red.svg";
import sanitize from "../helpers/sanitize";
import '../assets/stylesheets/footer.css';

const boxDisclaimer = (disclaimer) => {
  return <div className="box-disclaimer mt-4">
    <div className="box-disclaimer-text d-flex justify-content-center p-3" dangerouslySetInnerHTML={{ __html: disclaimer ? sanitize(disclaimer) : '' }} />
  </div>;
}

const poweredByRevvContainer = (image) => {
  return <div className="powered-by-revv text-center col-12">
  Powered By <a href="https://revv.co"><img className="powered-by-revv-logo ml-1" src={image} alt="Powered by Revv" /></a>
  </div>
}

const poweredByWinRedContainer = (image) => {
  return <div className="powered-by-revv text-center col-12">
  Powered By <a href="https://winred.com"><img className="powered-by-revv-logo ml-1" src={image} alt="Powered by WinRed" /></a>
  </div>
}

const legalUrls = (name, url) => {
  if(url) {
    return <a className="p-1" href={url}>
      {name}
    </a>
  } else {
    return
  }
}

function StorefrontFooter({ settings, data }){
  const { disclaimer='', long_disclaimer='', win_red_disclaimer='', win_red_long_disclaimer='' } = data.organization.store.settings;

  return (
    <div className="storefront-footer container-fluid" style={{ backgroundColor: settings.footer_background_color, color: settings.footer_text_color}}>
        <div className="container row footer-content py-5 mx-auto">
          <div className="col-12">
            <StorefrontLogo data={data} />
          </div>
          <div className="disclaimers py-4 col-12">
            {disclaimer ? boxDisclaimer(disclaimer) : null}
            <div className="long-disclaimer mt-4">
              <div className="long-disclaimer-text" dangerouslySetInnerHTML={{ __html: long_disclaimer ? sanitize(long_disclaimer)  : ''}} />
            </div>
            {win_red_disclaimer ? boxDisclaimer(win_red_disclaimer) : null}
            <div className="long-disclaimer mt-4">
              <div className="long-disclaimer-text" dangerouslySetInnerHTML={{ __html: win_red_long_disclaimer ? sanitize(win_red_long_disclaimer) : '' }} />
            </div>
          </div>
          {data.organization.win_red_store ? poweredByWinRedContainer(poweredByWinRedLogo) : poweredByRevvContainer(poweredByRevvLogo)}
          <div className="disclaimer-terms my-3 text-center col-12">
            {legalUrls('Terms of Use', data.organization.store.settings.terms_of_use_url)}
            {legalUrls('Privacy Policy', data.organization.store.settings.privacy_policy_url)}
            {legalUrls('About our Ads', data.organization.store.settings.about_our_ads_url)}
          </div>
        </div>
      </div>
      );

}

StorefrontFooter.propTypes = {
  settings: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}

export default StorefrontFooter;