import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';

import { GET_CART, GET_ORG_ID, CHECK_CART_ID, GET_STORE_ID } from '../constants/queries';
import { CREATE_CART, ADD_ITEM_TO_CART } from '../constants/mutations';

import VariantSelector from './VariantSelector';
import ImageCarousel from './ImageCarousel';

import Head from './Head';

import sanitize from '../helpers/sanitize';
import { getCookies } from '../helpers/cookieHelpers';
import { productType } from '../helpers/propTypes';
import { formatPrice } from '../helpers/presenters';

import '../assets/stylesheets/productDetails.css';
import Honeybadger from '@honeybadger-io/js';

const ProductDetails = ({ product, settings, data, cartId, storeId, history, winRedStore, winRedStoreSlug, slug }) => {
  const [selectedVariant, setSelectedVariant] = useState(
    product?.options?.length === 0 || product?.variants?.length === 1 ? product?.variants[0] : null
  );
  const [quantity, setQuantity] = useState(0);
  const [error, setError] = useState(null);

  const setVariant = variant => {
    let error = null;
    if (variant === null) {
      error = 'This product combination is currently unavailable.';
    }
    setSelectedVariant(variant);
    setError(error);
  };

  const updateQuantity = event => {
    setQuantity(parseInt(event.target.value, 10));
  };

  const images = useMemo(() => {
    let images = product?.product_images || [];
    images = [...images];
    product?.variants?.forEach((variant, index) => {
      if (variant?.variant_image) {
        images.push(variant?.variant_image);
      }
    });
    return images;
  }, [product]);

  const getSelectedImageIndex = useCallback(() => {
    const selectedImageIndex =
      (selectedVariant && images?.findIndex(image => image?.revv_uid === selectedVariant?.revv_uid)) || -1;
    if (selectedImageIndex > -1) return selectedImageIndex;

    return 0;
  }, [images, selectedVariant]);

  const renderQuantitySelector = () => {
    const variantQuantity = selectedVariant && selectedVariant.max_quantity;
    const options = Array.from({ length: variantQuantity }, (_, index) => index + 1);

    const disabled = !selectedVariant;
    const styles = { backgroundColor: disabled ? settings.secondary_color : settings.primary_color };
    return (
      <div className="mb-2">
        <select
          style={styles}
          className={`quantity-selector custom-select ${disabled ? 'disabled' : ''}`}
          value={quantity}
          onChange={updateQuantity}
          disabled={disabled}
        >
          <option value={0}>Select Quantity</option>
          {options.map(option => (
            <option key={option}>{option}</option>
          ))}
        </select>
      </div>
    );
  };

  const updateCart = (cache, { data: { addItemToCart } }) => {
    const { orgId } = cache.readQuery({ query: GET_ORG_ID });
    const { cartId } = cache.readQuery({ query: CHECK_CART_ID });
    const { storeId, revv_uid } = cache.readQuery({ query: GET_STORE_ID });
    const { organization } = cache.readQuery({ query: GET_CART, variables: { orgId: orgId, cartId: cartId } });

    let cart = { ...organization.store.cart };
    const newItem = { quantity: addItemToCart.cartItem.quantity, variant: addItemToCart.cartItem.variant };

    const existingItem = cart.cart_items.find(item => item.id === addItemToCart.cartItem.id);

    if (existingItem) {
      const existingItemCopy = { ...existingItem };
      existingItemCopy.quantity = addItemToCart.cartItem.quantity;
    } else {
      cart.cart_items = [...cart.cart_items, addItemToCart.cartItem];
    }

    cache.writeQuery({
      query: GET_CART,
      data: {
        organization: {
          __typename: 'Organization',
          revv_uid: orgId,
          win_red_store: winRedStore,
          win_red_store_slug: winRedStoreSlug,
          slug: slug,
          store: {
            id: storeId,
            revv_uid: revv_uid,
            __typename: 'Store',
            cart: cart,
          },
        },
      },
    });

    history.push('/', { addedItem: newItem });
  };

  const [addItemToCart, { error: errorAddItemToCart }] = useMutation(ADD_ITEM_TO_CART, {
    update: updateCart,
  });
  if (errorAddItemToCart) {
    const errorMessage = `[ADD_ITEM_TO_CART mutation]: Message: ${errorAddItemToCart}, storeId: ${storeId}, cartId: ${cartId}`;
    Honeybadger.notify(errorMessage);
    console.error(errorMessage);
  }

  const updateCartId = (cache, { data: { createCart } }) => {
    const { orgId } = cache.readQuery({ query: GET_ORG_ID });
    const { storeId, revv_uid } = cache.readQuery({ query: GET_STORE_ID });

    const newItem = { quantity: quantity, variant: selectedVariant && selectedVariant.revv_uid };

    cache.writeQuery({ query: CHECK_CART_ID, data: { cartId: createCart.cart.revv_uid } });
    cache.writeQuery({
      query: GET_CART,
      data: {
        organization: {
          __typename: 'Organization',
          revv_uid: orgId,
          store: { id: storeId, revv_uid: revv_uid, __typename: 'Store', cart: createCart.cart },
        },
      },
    });

    history.push('/', { addedItem: newItem });
  };

  const [createCart, { error: errorCreateCart }] = useMutation(CREATE_CART, {
    update: updateCartId,
  });
  if (errorCreateCart) {
    const errorMessage = `[CREATE_CART mutation]: Message: ${errorCreateCart}, storeId: ${storeId}, cartId: ${cartId}`;
    Honeybadger.notify(errorMessage);
    console.error(errorMessage);
  }

  const renderButton = (mutation, variables) => {
    const disabled = !selectedVariant || quantity === 0 || quantity > (selectedVariant && selectedVariant.max_quantity);

    return (
      <Button
        className={`add-to-cart-button font-weight-bold ${disabled ? 'disabled' : ''} w-100`}
        onClick={() => mutation({ variables: { ...variables } })}
        disabled={disabled}
      >
        + Add to Cart
      </Button>
    );
  };

  const renderCartMutation = () => {
    const newItem = { quantity: quantity, variant: selectedVariant && selectedVariant.revv_uid };
    const originDetails = getCookies();

    if (cartId) {
      return renderButton(addItemToCart, { item: newItem, revvUid: cartId, originDetailAttributes: originDetails });
    } else {
      return renderButton(createCart, {
        cartAttributes: { items: [newItem], merchStoreId: storeId },
        originDetailAttributes: originDetails,
      });
    }
  };

  const renderError = () => {
    if (error) {
      return <span className="error-message">{error}</span>;
    } else {
      return null;
    }
  };

  return (
    <div className="product-details row">
      <Head data={data} url={window.location.href} product={product} noStorefront />
      <div className="col-12 col-lg-8">
        <ImageCarousel images={images} selectedItem={getSelectedImageIndex()} />
      </div>
      <div className="product-info text-center text-lg-left col-12 col-lg">
        <h5 className="details-header mt-3">{product?.name}</h5>
        <p className="price">
          $
          {formatPrice(
            selectedVariant && selectedVariant?.variant_price ? selectedVariant?.variant_price : product.price
          )}
        </p>
        <div
          className="product-description py-3"
          dangerouslySetInnerHTML={{ __html: sanitize(product?.description) }}
        />
        <VariantSelector
          options={product?.options}
          variants={product?.variants}
          setVariant={setVariant}
          primaryColor={settings.primary_color}
        />
        {renderQuantitySelector()}
        {renderCartMutation()}
        {renderError()}
      </div>
    </div>
  );
};

ProductDetails.propTypes = {
  product: productType.isRequired,
  settings: PropTypes.shape({
    primary_color: PropTypes.string.isRequired,
    secondary_color: PropTypes.string.isRequired,
  }),
  data: PropTypes.object.isRequired,
  cartId: PropTypes.string,
  storeId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  winRedStore: PropTypes.bool,
  winRedStoreSlug: PropTypes.string,
  slug: PropTypes.string,
};

export default ProductDetails;
