export const getSelectedCollectionBySlug = (collections, collectionParam) => {
  // find matching collection id in collections and their children
  let selectedCollection = null;

  collections.forEach(collection => {
    if (collection.slug === collectionParam) {
      selectedCollection = collection;
      return;
    }

    if (collection.child_collections) {
      const childMatch = collection.child_collections.find(childCollection => childCollection.slug === collectionParam);
      if (childMatch) {
        selectedCollection = childMatch;
        return;
      }
    }
  });
  return selectedCollection;
};
