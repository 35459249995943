import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import MetaTags from './MetaTags';

const renderRevvGTMScript = () => {
  return (
    <script>{`(function(w,d,s,l,i){w[l] = w[l] || []; w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NTQZ9N');`}</script>
  );
};

const renderGTMScript = gtmContainerId => {
  return (
    <script>{`(function(w,d,s,l,i){w[l] = w[l] || []; w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmContainerId}');`}</script>
  );
};

const renderGTMNoScript = gtmContainerId => {
  return (
    <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmContainerId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
  );
};

const renderGATrackingScriptAsync = gaTrackingId => {
  return <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />;
};

const renderGATrackingScript = gaTrackingId => {
  return (
    <script>{`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    ga('create', '${gaTrackingId}', 'auto');
    ga('send', 'pageview');`}</script>
  );
};

const Head = ({ data, url, metaTags, noStorefront, product }) => {
  const og_video = data.organization.store.settings.og_video;
  const settings = data.organization.store.settings;
  const gtmContainerId = data.organization.gtm_integration_id;
  const gaTrackingId = data.organization.ga_integration_id;
  const noIndex = url.indexOf('/cart/') != -1;

  let _metaTags = metaTags || {
    name: data.organization.name,
    title: settings.title,
    ogDescription: (settings.og_description || '').trim(),
    ogTitle: settings.og_title,
    ogUrl: url,
    ogImage: settings.open_graph_image_url,
    ogImageWidth: 1200,
    ogImageHeight: 630,
    twitterUrl: url,
    twitterTitle: settings.twitter_title,
    twitterDescription: settings.twitter_description,
    twitterImage: settings.twitter_image_url,
  };

  product = product || (data.organization.store.store_item && data.organization.store.store_item.product);

  if(product && !metaTags){
    let ogImage = product.product_images && product.product_images[0] && product.product_images[0].image_url;
    ogImage = ogImage || (product.variants && product.variants[0] && product.variants[0].variant_image && product.variants[0].variant_image.image_url);

    _metaTags = {
      name: data.organization.name,
      title: settings.title,
      ogImageWidth: 1200,
      ogImageHeight: 630,
      ogDescription: (product.og_description || '').trim(),
      ogTitle: product.name,
      ogUrl: url,
      ogImage: ogImage,
      twitterUrl: url,
      twitterTitle: product.name,
      twitterDescription: (product.og_description || '').trim(),
      twitterImage: ogImage,
    }
  }

  return (
    <Fragment>
      <MetaTags {..._metaTags} noStorefront={noStorefront} />
      <Helmet>
        {renderRevvGTMScript()}
        {og_video ? <meta property="og:video" content={og_video} /> : null}
        {og_video ? <meta property="og:video:type" content="video/mp4" /> : null}
        {og_video ? <meta property="og:video:url" content={og_video} /> : null}
        {og_video ? <meta property="og:video:secure_url" content={og_video} /> : null}
        {og_video ? <meta property="og:video:width" content="300" /> : null}
        {og_video ? <meta property="og:video:height" content="169" /> : null}
        {gtmContainerId ? renderGTMScript(gtmContainerId) : null}
        {gtmContainerId ? renderGTMNoScript(gtmContainerId) : null}
        {gaTrackingId ? renderGATrackingScriptAsync(gaTrackingId) : null}
        {gaTrackingId ? renderGATrackingScript(gaTrackingId) : null}
        {noIndex ? <meta name="robots" content="noindex" /> : null}
      </Helmet>
    </Fragment>
  );
};

export default Head;
