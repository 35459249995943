import React from 'react';
import PropTypes from 'prop-types';

import '../assets/stylesheets/loadingIndicator.css';

const DEFAULT_COLOR_HEX = '#333';

const LoadingIndicator = ({color}) => (
  <div className="text-center">
    <div className="lds-ellipsis">
      <div style={{background: color}} />
      <div style={{background: color}} />
      <div style={{background: color}} />
      <div style={{background: color}} />
    </div>
  </div>
);

LoadingIndicator.defaultProps = {
  color: DEFAULT_COLOR_HEX,
};

LoadingIndicator.propTypes = {
  color: PropTypes.string,
};

export default LoadingIndicator;