import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_CART } from '../constants/queries';
import CartButton from './CartButton';
import CheckoutButton from './CheckoutButton';
import '../assets/stylesheets/addedToCart.css';
import { formatPrice } from '../helpers/presenters';
import EMPTY_IMAGE from '../assets/images/empty.jpg';
import PropTypes from 'prop-types';
import Honeybadger from '@honeybadger-io/js';

const AddedToCart = ({ location, primaryColor, orgId, cartId }) => {
  const displayProductOptions = options => {
    return options?.map(option => {
      return (
        <span key={option.option.name} className="item-option d-inline-block text-truncate mx-1">
          <strong>{option.option.name}</strong>:&nbsp;{option.value}
        </span>
      );
    });
  };

  const item = location.state && location.state.addedItem;

  if (item) {
    const variant = item.variant;

    let imageUrl = EMPTY_IMAGE;
    if (variant.variant_image) {
      imageUrl = variant.variant_image.image_url;
    } else if (variant.product?.product_images[0]) {
      imageUrl = variant.product?.product_images[0]?.image_url;
    }

    if (variant && cartId) {
      const { data, loading, error } = useQuery(GET_CART, {
        variables: { cartId: cartId, orgId: orgId },
      });

      if (error) {
        const errorMessage = `[GET_CART query]: Message: ${error}, cartId: ${cartId}, orgId: ${orgId}`;
        Honeybadger.notify(errorMessage);
        console.error(error);
      }

      if (loading || error || !data?.organization) return null;

      const cart = data.organization.store.cart;

      return (
        <div className="row added-to-cart p-3 mx-0 d-md-flex align-items-center">
          <div className="col-md-1 col-xs-12 text-center my-2 my-md-0">
            <img className="cart-item-image text-truncate" src={imageUrl} alt={variant.name} />
          </div>
          <div className="col-lg col-md-8 text-center text-md-left my-2 my-md-0">
            <h5 className="mb-0 font-weight-bold">Added to Cart</h5>
            <span>
              {variant.name}:&nbsp;${formatPrice(variant?.variant_price)}
            </span>
          </div>
          <div className="col-auto item-options my-md-3 border p-3 d-none d-md-flex align-items-center">
            {displayProductOptions(variant.options)}
            <span>
              Qty:&nbsp;<strong>{item.quantity}</strong>
            </span>
          </div>
          <div className="w-100 d-none d-block d-lg-none" />
          <div className="col-12 col-md-auto text-center text-lg-right pl-md-0 pr-md-0 my-2 my-md-0">
            <CartButton
              className="rv-button-secondary border font-weight-bold mr-0 mr-md-3 ml-lg-3 px-4"
              text={'View Cart'}
              orgId={orgId}
              cartId={cartId}
            />
          </div>
          <div className="col-12 col-md-auto text-center text-lg-right pl-md-0 mt-2 mb-3 my-md-0">
            <CheckoutButton cartItems={cart?.cart_items} primaryColor={primaryColor} orgId={orgId} cartId={cartId} />
          </div>
        </div>
      );
    }
  }

  return null;
};

AddedToCart.propTypes = {
  location: PropTypes.object.isRequired,
  primaryColor: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
};

export default AddedToCart;
