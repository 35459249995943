import gql from "graphql-tag";

export const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      user {
        id
        email
        first_name
      }
      token
    }
  }
`;

const itemDetails = gql`
  fragment itemDetails on CartItem {
    id
    quantity
    variant {
      revv_uid
      name
      variant_price
      additional_shipping_cost
      max_quantity
      variant_image {
        image_url
      }
      options {
        value
        option {
          name
        }
      }
      product {
        revv_uid
        name
        description
        price
        options {
          name
        }
        product_images {
          image_url
        }
      }
    }
  }
`;

const cartDetails = gql`
  fragment cartDetails on Cart {
    revv_uid
    status
    cart_items {
      ...itemDetails
    }
  }
  ${itemDetails}
`;

export const Cart = {
  fragments: {
    itemDetails,
    cartDetails,
  },
};

export const ADD_ITEM_TO_CART = gql`
  mutation addItemToCart(
    $item: CartItemInput!
    $revvUid: String!
    $originDetailAttributes: OriginDetailInput
  ) {
    addItemToCart(
      revvUid: $revvUid
      item: $item
      originDetailAttributes: $originDetailAttributes
    ) {
      cartItem {
        ...itemDetails
      }
      errors
      newCart {
        ...cartDetails
      }
    }
  }
  ${Cart.fragments.itemDetails}
  ${Cart.fragments.cartDetails}
`;

export const REMOVE_ITEM_FROM_CART = gql`
  mutation removeItemFromCart($cartId: String!, $itemId: Int!) {
    removeItemFromCart(cartId: $cartId, id: $itemId) {
      item {
        ...itemDetails
      }
    }
  }
  ${Cart.fragments.itemDetails}
`;

export const UPDATE_CART_ITEM = gql`
  mutation updateCartItem($cartId: String!, $itemId: Int!, $quantity: Int!) {
    updateCartItem(cartId: $cartId, id: $itemId, quantity: $quantity) {
      item {
        ...itemDetails
      }
      errors
    }
  }
  ${Cart.fragments.itemDetails}
`;

export const CREATE_CART = gql`
  mutation createCart(
    $cartAttributes: CartInput!
    $originDetailAttributes: OriginDetailInput!
  ) {
    createCart(
      cartAttributes: $cartAttributes
      originDetailAttributes: $originDetailAttributes
    ) {
      cart {
        revv_uid
        status
        cart_items {
          ...itemDetails
        }
      }
      errors
    }
  }
  ${Cart.fragments.itemDetails}
`;

export const UPDATE_CART_ORIGIN_DETAIL = gql`
  mutation updateCartOriginDetail(
    $cartId: String
    $originDetailAttributes: OriginDetailInput!
  ) {
    updateCartOriginDetail(
      cartId: $cartId
      originDetailAttributes: $originDetailAttributes
    ) {
      cart {
        revv_uid
      }
    }
  }
`;
