import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

function MetaTags({
  name,
  title,
  ogDescription,
  ogTitle,
  ogUrl,
  ogImage,
  ogImageWidth,
  ogImageHeight,
  twitterUrl,
  twitterTitle,
  twitterDescription,
  twitterImage,
  noStorefront,
}) {

  const _description = `${ogTitle || name}`;
  const _ogTitle = `${ogTitle || name}`;
  const _ogDescription = `${ogDescription || name}`;
  const _twitterTitle = `${twitterTitle || name}`;
  const _twitterDescription = `${twitterDescription || name}`;
  const _twitterImage = `${twitterImage || name}`;

  const hasTrailingSlash = ogUrl.charAt(ogUrl.length - 1) === "/";
  if(!hasTrailingSlash) ogUrl += `${ogUrl}/`;

  return (
    <Helmet>
      <title>{title}</title>
      {ogUrl ? <link rel="canonical" href={ogUrl} /> : null}
      
      {ogTitle || name ? <meta name="description" content={_description} /> : null}

      {ogTitle || name ? <meta property="og:title" content={_ogTitle} /> : null}
      {ogDescription || name ? <meta property="og:description" content={_ogDescription} /> : null}
      {ogUrl ? <meta property="og:url" content={ogUrl} /> : null}
      {name ? <meta property="og:site_name" content={name} /> : null}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      {ogImage ? <meta property="og:image" content={ogImage} /> : null}
      {ogImage ? <meta property="og:image:width" content={ogImageWidth} /> : null}
      {ogImage ? <meta property="og:image:height" content={ogImageHeight} /> : null}

      <meta property="twitter:card" content="summary_large_image" />
      {twitterUrl ? <meta property="twitter:url" content={twitterUrl} /> : null}
      {twitterTitle || name ? <meta property="twitter:title" content={_twitterTitle} /> : null}
      {twitterDescription || name ? <meta property="twitter:description" content={_twitterDescription} /> : null}
      {twitterImage || name ? <meta property="twitter:image" content={_twitterImage} /> : null}
    </Helmet>
  );
}

MetaTags.defaultProps = {
  name: '',
  title: 'Store',
  ogDescription: '',
  ogTitle: '',
  ogUrl: '',
  ogImage: '',
  ogImageWidth: '',
  ogImageHeight: '',
  twitterUrl: '',
  twitterTitle: '',
  twitterDescription: '',
  twitterImage: '',
  noStorefront: false,
};

MetaTags.propTypes = {
  noStorefront: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  ogDescription: PropTypes.string,
  ogTitle: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,
  ogImageWidth: PropTypes.number,
  ogImageHeight: PropTypes.number,
  twitterUrl: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.string,
};

export default MetaTags;
