import React from 'react';
import { CardTitle, CardText } from 'reactstrap';

import { formatPrice } from '../../helpers/presenters';

const OrderTotal = ({ shipping , total }) => {
  return (
    <div>
      <CardText className="shipping px-3">Shipping <span className="pull-right strong">${formatPrice(shipping)}</span></CardText>
      <CardTitle className="card-title total pl-3 py-3">Total <span className="pull-right strong">${formatPrice(total)}</span></CardTitle>
    </div>
  )
}

export default OrderTotal;
