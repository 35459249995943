import Cookies from "js-cookie";
const UtmList = [
  "utm_source",
  "utm_term",
  "utm_campaign",
  "utm_medium",
  "utm_content"
];
const OtherParams = [
  "sourceUrl",
  "userAgent",
  "sourceCode"
]

const camelCase = (param) => {
  const keyWords = param.split("_");
  return keyWords[0].concat(keyWords[1][0].toUpperCase() + keyWords[1].slice(1));
}

export const setUtms = (params) => {
  const utmParams = {};
  Object.keys(params).forEach(key => {
    if (UtmList.includes(key)) {
      utmParams[key] = params[key];
    }
  });
  const foundParams = Object.keys(utmParams);
  if (foundParams.length > 0) {
    clearUtms();
    foundParams.forEach((param) => {
      const camelCased = camelCase(param);
      Cookies.set(camelCased, utmParams[param]);
    });
  }
}

export const getCookies = () => {
  const cookKeys = OtherParams.concat(UtmList.map((utm) => camelCase(utm)));
  let originDetailAttributes = {};
  cookKeys.forEach((attr) => Cookies.get(attr) ? originDetailAttributes[attr] = Cookies.get(attr) : null);
  return originDetailAttributes;
}

export const getCookie = (key) => {
  return Cookies.get(key);
}

export const setCookie = (key, value) => {
  Cookies.set(key, value);
}

export const deleteCookie = (key) => {
  Cookies.remove(key);
}

export const clearUtms = () => {
  UtmList.forEach((param) => {
    const camelCased = camelCase(param);
    Cookies.remove(camelCased);
  });
  OtherParams.forEach((param) => Cookies.remove(param));
}