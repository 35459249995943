import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import '../assets/stylesheets/pagination.css';
/*
This was migrated from the revv-winred repo. 
setPage was initially called with a specified field name, for the purpose
of retaining filter settings on pages. However, that functionality is not being
implemented at this time, so setPage just needs the page number

currentPage, pageCount, and totalCount should come from the API

currentPage is the page number that is currently being viewed
pageCount is the amount on the current Page
totalCount is the total number of items available across all pages
*/

function Pagination({ setPage, currentPage, limit, pageCount, totalCount, primaryColor }) {
  // If there is only one page, don't show these controls.
  if (pageCount === totalCount && currentPage === 1) {
    return null;
  }

  const isFirstPage = currentPage === 1;

  const isLastPage = pageCount * currentPage === totalCount || pageCount < limit;

  const prevPage = () => {
    setPage(currentPage - 1);
  };

  const nextPage = () => {
    setPage(currentPage + 1);
  };

  return (
    <div className="pagination-container">
      <Button 
        color="primary"
        size="lg"
        onClick={prevPage}
        disabled={isFirstPage}
        style={{visibility: isFirstPage ? 'hidden' : 'initial', backgroundColor: primaryColor}}
      >
        Previous
      </Button>
      <Button
        color="primary"
        size="lg"
        onClick={nextPage}
        disabled={isLastPage}
        style={{visibility: isLastPage ? 'hidden' : 'initial', backgroundColor: primaryColor}}
      >
        Next
      </Button>
    </div>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  primaryColor: PropTypes.string.isRequired,
};

export default Pagination;
