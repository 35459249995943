export const computeCosts = ({ data, subtotal, cartItems }) => {
  let total = subtotal;
  let shipping = 0;

  const shippingRates = data.organization.store.shipping_rates || [];

  // shipping rate depends on subtotal of all items - find proper shipping rate based on subtotal
  const cost = shippingRates.find(rate => rate.min_total <= subtotal && rate.max_total >= subtotal);

  // some variants may have additional shipping costs - add them up here
  const additionalCost = cartItems.reduce((acc, item) => {
    return acc + item?.variant?.additional_shipping_cost * item?.quantity;
  }, 0);

  // add up shipping based on what we calculated
  if (cost) shipping += cost.shipping_cost || 0;
  if (additionalCost) shipping += additionalCost;

  total = subtotal + shipping;

  return { shipping, total };
};
