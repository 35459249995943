import PropTypes from 'prop-types';

export const variantType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  revv_uid: PropTypes.string.isRequired,
  max_quantity: PropTypes.number.isRequired,
  variant_image: PropTypes.shape({
    image_url: PropTypes.string,
  }),
  variant_price: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}).isRequired;

export const optionType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  option_values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      variant: PropTypes.shape({
        revv_uid: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
});

export const productType = PropTypes.shape({
  revv_uid: PropTypes.string.isRequired,
  friendly_id: PropTypes.string,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.string,
  product_images: PropTypes.arrayOf(
    PropTypes.shape({
      image_url: PropTypes.string,
    })
  ),
  variants: PropTypes.arrayOf(variantType).isRequired,
  options: PropTypes.arrayOf(optionType).isRequired,
});
