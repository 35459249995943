import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ApolloConsumer } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Breakpoint } from 'react-socks';

import '../assets/stylesheets/heroImage.css';
import { GET_ORG_ID } from '../constants/queries';

const HERO_SIZE_TYPES = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
};

const HeroImage = ({ type, settings, selectedCollection }) => {
  const desktopImage = selectedCollection.hero_image_url || settings.hero_image_url;
  const mobileImage = selectedCollection.hero_mobile_image_url || settings.hero_mobile_image_url;
  const backgroundImageUrl = type === HERO_SIZE_TYPES.MOBILE ? mobileImage : desktopImage;

  if (!backgroundImageUrl) return null;
  return <img className="hero-image" alt="hero product" src={backgroundImageUrl} />;
};

const HeroButton = ({ productId, settings, selectedCollection, type }) => {
  // if collection has its own image then no product associated with image so dont show link
  const collectionHasImage = selectedCollection.hero_image_url || selectedCollection.hero_mobile_image_url;
  if (collectionHasImage) return null;

  // if no image then dont show button either
  const desktopImage = selectedCollection.hero_image_url || settings.hero_image_url;
  const mobileImage = selectedCollection.hero_mobile_image_url || settings.hero_mobile_image_url;
  const backgroundImageUrl = type === HERO_SIZE_TYPES.MOBILE ? mobileImage : desktopImage;
  if (!backgroundImageUrl) return null;

  return (
    <ApolloConsumer>
      {client => {
        const { orgId } = client.readQuery({ query: GET_ORG_ID });
        if (!orgId || !productId) return null;

        return (
          <Link
            to={{ pathname: `/${productId}/details`, state: { productSlug: productId } }}
            className="hero-button-container"
          >
            <Button className="hero-image-btn py-3 px-2" style={{ backgroundColor: settings.primary_color }}>
              View Product
            </Button>
          </Link>
        );
      }}
    </ApolloConsumer>
  );
};

function HeroImageContainer({ settings, selectedCollection }) {
  const revv_uid = settings.hero_image_product && settings.hero_image_product.revv_uid;
  const friendlyID = settings.hero_image_product && settings.hero_image_product.friendly_id;
  const productId = friendlyID || revv_uid;

  return (
    <Fragment>
      <Breakpoint small down>
        <div className="hero-image-container">
          <div className="hero-overlay">
            <HeroImage type={HERO_SIZE_TYPES.MOBILE} settings={settings} selectedCollection={selectedCollection} />
            <HeroButton
              productId={productId}
              type={HERO_SIZE_TYPES.MOBILE}
              settings={settings}
              selectedCollection={selectedCollection}
            />
          </div>
        </div>
      </Breakpoint>
      <Breakpoint medium up>
        <div className="hero-image-container">
          <div className="hero-overlay">
            <HeroImage type={HERO_SIZE_TYPES.DESKTOP} settings={settings} selectedCollection={selectedCollection} />
            <HeroButton
              productId={productId}
              type={HERO_SIZE_TYPES.DESKTOP}
              settings={settings}
              selectedCollection={selectedCollection}
            />
          </div>
        </div>
      </Breakpoint>
    </Fragment>
  );
}

HeroImageContainer.propTypes = {
  settings: PropTypes.shape({
    hero_image_product: PropTypes.shape({
      revv_uid: PropTypes.string.isRequired,
      friendly_id: PropTypes.string.isRequired,
    }),
  }),
  collections: PropTypes.array.isRequired,
  selectedCollection: PropTypes.object.isRequired,
};

export default HeroImageContainer;
