import React, { Component } from 'react';
import PropTypes from 'prop-types';
class NavBarMobile extends Component {
  // componentDidMount() {
  //   window.$('.dropdown-menu').on('click', function (e) {
  //     console.log({ e });
  //     window.$(this).parent().is('.open') && e.stopPropagation();
  //   });
  // }

  render() {
    const { collections, onChangeCollection, primaryColor, selectedCollection } = this.props;

    return (
      <nav className="navbar navbar-expand-lg navbar-mobile navbar-light pr-0">
        <button
          className="navbar-toggler navbar-toggler-main"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ backgroundColor: primaryColor }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav-header">
            <div>Shop Categories</div>
            <button
              type="button"
              className="navbar-toggler"
              style={{ backgroundColor: primaryColor }}
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              x
            </button>
          </div>

          <ul className="navbar-nav navbar-nav-mobile mr-auto">
            <li className="nav-item all-link">
              <a
                className="nav-link collection-all"
                href="#"
                data-collection=""
                onClick={onChangeCollection}
                style={{ color: selectedCollection.revv_uid === '' ? primaryColor : null }}
              >
                All
              </a>
            </li>
            {collections.map(collection => {
              const hasChildren = collection.child_collections && collection.child_collections.length > 0;

              if (hasChildren) {
                return (
                  <li className="nav-item dropdown open show" key={collection.revv_uid}>
                    <a
                      className="nav-link dropdown-toggle collection"
                      data-collection={collection.slug}
                      onClick={onChangeCollection}
                      href="#"
                      id={'navbarDropdown' + collection.revv_uid}
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                      style={{ color: selectedCollection.revv_uid === collection.revv_uid ? primaryColor : null }}
                    >
                      {collection.internal_name}
                    </a>
                    <div
                      className="dropdown-menu stay-open show"
                      aria-labelledby={'navbarDropdown' + collection.revv_uid}
                    >
                      {collection.child_collections.map(child => {
                        return (
                          <a
                            key={child.revv_uid}
                            className="dropdown-item collection"
                            data-collection={child.slug}
                            href="#"
                            onClick={onChangeCollection}
                            style={{ color: selectedCollection.revv_uid === child.revv_uid ? primaryColor : null }}
                          >
                            {child.internal_name}
                          </a>
                        );
                      })}
                    </div>
                  </li>
                );
              } else {
                return (
                  <li className="nav-item" key={collection.revv_uid}>
                    <a
                      className="nav-link collection"
                      href="#"
                      data-collection={collection.slug}
                      onClick={onChangeCollection}
                      style={{ color: selectedCollection.revv_uid === collection.revv_uid ? primaryColor : null }}
                    >
                      {collection.internal_name}
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </nav>
    );
  }
}

NavBarMobile.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  collections: PropTypes.array.isRequired,
  onChangeCollection: PropTypes.func.isRequired,
  selectedCollection: PropTypes.object.isRequired,
};

export default NavBarMobile;
