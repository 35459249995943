import React from 'react';
import PropTypes from 'prop-types';

import ProductsList from './ProductsList';
import HeroImageContainer from './HeroImage';
import Head from './Head';

function Home({ settings, collections, getCollectionFromParams, data, selectedCollection }) {

  return  (
    <div>
      <Head data={data} url={window.location.href} />
      <HeroImageContainer settings={settings} collections={collections} getCollectionFromParams={getCollectionFromParams} selectedCollection={selectedCollection} />
      <ProductsList settings={settings} collections={collections} getCollectionFromParams={getCollectionFromParams} selectedCollection={selectedCollection} />
    </div>
  );
}

Home.propTypes = {
  collections: PropTypes.array.isRequired,
  settings: PropTypes.object.isRequired,
  selectedCollection: PropTypes.object.isRequired,
};

export default Home;