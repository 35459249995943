import sanitizeHTML from 'sanitize-html-react';

const defaultAttrs = ["class", "id", "style"];

const defaultOptions = {
  allowedTags: [
    "a",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "div",
    "span",
    "img",
    "table",
    "tbody",
    "tr",
    "th",
    "thead",
    "td",
    "b",
    "em",
    "strong",
    "u",
    "blockquote",
    "br",
    "hr",
    "ul",
    "ol",
    "li",
    "strike",
    "sub",
    "sup",
    "center"
  ],
  allowedAttributes: {
    "*": defaultAttrs,
    a: defaultAttrs.concat(["href", "rel", "target"]),
    div: defaultAttrs.concat(["width", "height"]),
    span: defaultAttrs.concat(["width", "height"]),
    img: defaultAttrs.concat(["src", "width", "height"]),
    table: defaultAttrs.concat(["alt", "align", "height", "width"]),
    tbody: defaultAttrs.concat(["align", "width"])
  },
  selfClosing: [
    "img",
    "br",
    "hr",
    "area",
    "base",
    "basefont",
    "input",
    "link",
    "meta"
  ],
  allowedClasses: {
    '*': ['text-align', 'text-decoration']
  },
  // URL schemes we permit
  allowedSchemes: ["http", "https", "ftp", "mailto"],
  allowedSchemesByTag: {}
};

const sanitize = (html) => {
  return sanitizeHTML(html, defaultOptions);
}

export default sanitize;