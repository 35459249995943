import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { variantType } from '../helpers/propTypes';
import { REMOVE_ITEM_FROM_CART, UPDATE_CART_ITEM } from '../constants/mutations';
import { GET_CART, GET_ORG_ID, GET_STORE_ID } from '../constants/queries';
import { formatPrice } from '../helpers/presenters';
import EMPTY_IMAGE from '../assets/images/empty.jpg';
import Honeybadger from '@honeybadger-io/js';

export const CartItem = ({ item, orgId, cartId }) => {
  const [removeItemFromCart, { error: errorRemoveItemFromCart }] = useMutation(REMOVE_ITEM_FROM_CART, {
    update: cache => removeCartItem(cache, cartId, item.id),
  });
  if (errorRemoveItemFromCart) {
    const errorMessage = `[REMOVE_ITEM_FROM_CART mutation]: Message: ${errorRemoveItemFromCart},orgId: ${orgId}, cartId: ${cartId}, itemId: ${item.id}`;
    Honeybadger.notify(errorMessage);
    console.error(errorMessage);
  }

  const [updateCartItem, { error: errorUpdateCartItem }] = useMutation(UPDATE_CART_ITEM);
  if (errorUpdateCartItem) {
    const errorMessage = `[UPDATE_CART_ITEM mutation]: Message: ${errorUpdateCartItem}, orgId: ${orgId}, cartId: ${cartId}, itemId: ${item.id}`;
    Honeybadger.notify(errorMessage);
    console.error(errorMessage);
  }

  const removeCartItem = (cache, cartId, itemId) => {
    const { orgId } = cache.readQuery({ query: GET_ORG_ID });
    const { storeId, revv_uid } = cache.readQuery({ query: GET_STORE_ID });
    const { organization } = cache.readQuery({
      query: GET_CART,
      variables: { orgId: orgId, cartId: cartId },
    });
    const cart = { ...organization.store.cart };
    const items = cart.cart_items;
    // find item in cache by id
    const itemIndex = items.findIndex(item => {
      return item.id === itemId;
    });
    // remove item from current cache
    if (itemIndex !== -1) {
      cart.cart_items = [...items.slice(0, itemIndex), ...items.slice(itemIndex + 1)];
    }
    cache.writeQuery({
      query: GET_CART,
      variables: { orgId: orgId, cartId: cartId },
      data: {
        organization: {
          __typename: 'Organization',
          revv_uid: orgId,
          store: {
            __typename: 'Store',
            id: storeId,
            revv_uid: revv_uid,
            cart: cart,
          },
        },
      },
    });
  };

  const displayProductOptions = options => {
    return options.map(option => {
      return (
        <span key={option.option.name} className="item-option d-inline-block text-truncate mr-1">
          <strong>{option.option.name}</strong>:&nbsp;{option.value}
        </span>
      );
    });
  };

  const removeFromCartButton = () => {
    return (
      <a
        className="remove-from-cart-button text-center text-md-left font-weight-light"
        onClick={() => removeItemFromCart({ variables: { itemId: item.id, cartId: cartId } })}
      >
        Remove
      </a>
    );
  };

  const quantitySelector = () => {
    let options = [];
    for (let i = 1; i < item.variant.max_quantity + 1; i++) {
      options.push(<option key={i}>{i}</option>);
    }
    return (
      <div className="col-8 col-sm col-md-8 col-lg">
        <div className="item-quantity d-flex align-items-center">
          <span className="pr-1">Qty: </span>
          <select
            value={item.quantity}
            className="item-quantity-selector custom-select"
            onChange={e =>
              updateCartItem({
                variables: {
                  cartId: cartId,
                  quantity: parseInt(e.target.value, 10),
                  itemId: item.id,
                },
              })
            }
          >
            {options.map(option => option)}
          </select>
        </div>
      </div>
    );
  };

  let imageUrl = EMPTY_IMAGE;
  if (item.variant.variant_image) {
    imageUrl = item.variant.variant_image.image_url;
  } else if (item.variant.product.product_images[0]) {
    imageUrl = item.variant.product.product_images[0].image_url;
  }

  return (
    <div className="row cart-item align-items-center mb-3 py-2">
      <div className="col-auto mx-auto">
        <img className="cart-item-image text-truncate" src={imageUrl} alt={item.variant.name} />
      </div>
      <div className="col-12 col-lg-6 text-center text-lg-left">
        <div className="row">
          <div className="col-12">
            <h5 className="my-0 item-name font-weight-bold">{item.variant.name}</h5>
          </div>
          <div className="col-sm-8 col-md-auto mx-auto mx-lg-0">{displayProductOptions(item.variant.options)}</div>
          <div className="w-100 d-block d-lg-none" />
          <div className="col-sm col-md">{removeFromCartButton()}</div>
        </div>
      </div>
      {quantitySelector()}
      <div className="col">
        <h5 className="item-price font-weight-bold text-right mb-0">
          ${formatPrice(item.variant?.variant_price * item.quantity)}
        </h5>
      </div>
    </div>
  );
};

CartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    variant: variantType,
  }).isRequired,
  cartId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
};
