import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '../assets/stylesheets/productTile.css';
import { formatPrice } from '../helpers/presenters';
import EMPTY_IMAGE from '../assets/images/empty.jpg';

const ProductTile = ({ product }) => {
  let imageUrl = EMPTY_IMAGE;

  if (product.product_images[0]) {
    imageUrl = product.product_images[0].image_url;
  } else if (product.variants[0] && product.variants[0].variant_image) {
    imageUrl = product.variants[0].variant_image.image_url;
  }

  let description = 'product';
  if (product.product_images[0]) {
    description = product.product_images[0].description;
  } else if (product.variants[0] && product.variants[0].description) {
    description = product.variants[0].variant_image.description;
  }

  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 product-tile mb-4 text-center">
      <Link
        className="product-link"
        to={{
          pathname: `/${product.friendly_id || product.revv_uid}/details`,
          state: { productSlug: product.slug },
        }}
      >
        <div className="product-image-container">
          <img alt={description} className="product-image mx-auto border" src={imageUrl} />
        </div>
      </Link>
      <div className="product-info">
        <div className="pt-3">
          <h5 className="mb-0 text-break">{product.name}</h5>
        </div>
        <div className="product-price py-md-2">${formatPrice(product.price)}</div>
      </div>
    </div>
  );
};

ProductTile.propTypes = {
  product: PropTypes.shape({
    revv_uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    variants: PropTypes.array.isRequired,
    product_images: PropTypes.arrayOf(
      PropTypes.shape({
        image_url: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default ProductTile;
