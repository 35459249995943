import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_PRODUCT } from '../constants/queries';
import ProductDetails from './ProductDetails';
import LoadingIndicator from './LoadingIndicator';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Honeybadger from '@honeybadger-io/js';
import { getProductSlug } from '../helpers/presenters';

const GetProduct = props => {
  const { orgId, cartId, productSlug, settings } = props;

  const location = useLocation();
  const slugFromLocation = location.state?.productSlug || getProductSlug(location?.pathname);

  let dataProduct, loadingProduct, errorProduct;
  try {
    ({
      data: dataProduct,
      loading: loadingProduct,
      error: errorProduct,
    } = useQuery(GET_PRODUCT, {
      variables: { orgId: orgId, productSlug: slugFromLocation || productSlug },
      fetchPolicy: 'network-only',
    }));
    if (errorProduct) {
      const errorMessage = `[GET_PRODUCT query]: Message: ${errorProduct}, orgId: ${orgId}, productSlug: ${
        productSlug || slugFromLocation
      }`;
      Honeybadger.notify(errorMessage);
      console.error(errorProduct);
    }
  } catch (e) {
    const errorMessage = `[GET_PRODUCT query]: Message: ${e}, orgId: ${orgId}, productSlug: ${
      productSlug || slugFromLocation
    }`;
    Honeybadger.notify(errorMessage);
    console.error(e);
  }

  if (errorProduct) return null;
  if (loadingProduct) return <LoadingIndicator color={settings.primary_color} />;

  const product = dataProduct?.organization.store?.store_item?.product;
  const winRedStore = dataProduct?.organization?.win_red_store;
  const winRedStoreSlug = dataProduct?.organization?.win_red_store_slug;
  const slug = dataProduct?.organization?.slug;

  if (!product) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <h1>Product Not Found.</h1>
      </div>
    );
  }

  return (
    <ProductDetails
      product={product}
      {...props}
      cartId={cartId}
      winRedStore={winRedStore}
      winRedStoreSlug={winRedStoreSlug}
      slug={slug}
    />
  );
};

GetProduct.propTypes = {
  settings: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  productSlug: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
};

export default GetProduct;
