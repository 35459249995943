import React from 'react';

import { CartItems } from './CartItems';
import OrderSummary from './orderSummary/OrderSummary';
import Head from './Head';
import '../assets/stylesheets/cart.css';

const ShoppingCart = ({ primaryColor, data, orgId, cartId }) => {
  return (
    <div className="row cart-view">
      <Head data={data} url={window.location.href} />
      <CartItems primaryColor={primaryColor} orgId={orgId} cartId={cartId} />
      <OrderSummary primaryColor={primaryColor} orgId={orgId} cartId={cartId} />
    </div>
  );
};

export default ShoppingCart;
