import { gql } from '@apollo/client';
import { Cart } from './mutations';

const variantDetails = gql`
  fragment variantDetails on ProductVariant {
    revv_uid
    name
    max_quantity
    variant_price
    additional_shipping_cost
    variant_image {
      image_url
      revv_uid
    }
    options {
      value
      option {
        name
      }
    }
    product {
      slug
      revv_uid
      name
      description
      price
      age_group
      custom_color
      color
      gender
      options {
        name
      }
      product_images {
        image_url
        revv_uid
      }
    }
  }
`;

const productDetails = gql`
  fragment productDetails on Product {
    revv_uid
    friendly_id
    name
    description
    og_description
    price
    slug
    options {
      name
      option_values {
        value
        variant {
          revv_uid
        }
      }
    }
    product_images {
      image_url
      revv_uid
    }
    variants {
      ...variantDetails
    }
  }
  ${variantDetails}
`;

export const StoreItem = {
  fragments: {
    productDetails,
    variantDetails,
  },
};

export const PRODUCTS_QUERY = gql`
  query ProductsQuery($revv_uid: String!, $collection: String, $limit: Int) {
    organization(revv_uid: $revv_uid) {
      revv_uid
      win_red_store
      win_red_store_slug
      slug
      store {
        id
        revv_uid
        store_items(collection: $collection, limit: $limit) {
          results {
            product {
              ...productDetails
            }
          }
        }
      }
    }
  }
  ${StoreItem.fragments.productDetails}
`;

export const PRODUCTS_QUERY_PAGED = gql`
  query ProductsQueryPaged($revv_uid: String!, $page: Int, $limit: Int, $collection: String) {
    organization(revv_uid: $revv_uid) {
      revv_uid
      win_red_store
      win_red_store_slug
      slug
      store {
        id
        revv_uid
        store_items(page: $page, limit: $limit, collection: $collection) {
          current_page
          page_count
          total_count
          results {
            product {
              ...productDetails
            }
          }
        }
      }
    }
  }
  ${StoreItem.fragments.productDetails}
`;

export const ROOT_QUERY = gql`
  query RootQuery($revvUid: String, $pathName: String) {
    organization(revv_uid: $revvUid, path_name: $pathName) {
      revv_uid
      name
      token
      ga_integration_id
      gtm_integration_id
      win_red_store
      win_red_store_slug
      slug
      store {
        id
        revv_uid
        collections {
          hero_image_url
          hero_mobile_image_url
          internal_name
          revv_uid
          slug
          child_collections {
            hero_image_url
            hero_mobile_image_url
            internal_name
            revv_uid
            slug
          }
        }
        settings {
          title
          disclaimer
          long_disclaimer
          maximum_contribution
          logo_url
          favicon_url
          og_title
          og_description
          og_video
          twitter_title
          twitter_description
          open_graph_image_url
          twitter_image_url
          checkout_url
          win_red_checkout_url
          background_color
          primary_color
          secondary_color
          footer_text_color
          footer_background_color
          win_red_disclaimer
          win_red_long_disclaimer
          terms_of_use_url
          privacy_policy_url
          about_our_ads_url
          hero_image_url
          hero_mobile_image_url
          hero_image_product {
            name
            revv_uid
            friendly_id
          }
          enable_product_pagination
        }
        shipping_rates {
          shipping_cost
          min_total
          max_total
        }
        cart {
          id
          revv_uid
        }
      }
    }
  }
`;

// local
export const GET_MODAL_STATE = gql`
  {
    isModalOpen @client
  }
`;

export const CHECK_CART_ID = gql`
  {
    cartId @client
  }
`;

export const GET_STORE_ID = gql`
  {
    storeId @client
    revv_uid @client
  }
`;

export const GET_ORG_ID = gql`
  {
    orgId @client
  }
`;

export const GET_PRODUCT_SLUG = gql`
  {
    productSlug @client
  }
`;

export const GET_CART = gql`
  query getCart($orgId: String!, $cartId: String!) {
    organization(revv_uid: $orgId) {
      revv_uid
      win_red_store
      win_red_store_slug
      slug
      store {
        id
        revv_uid
        cart(revv_uid: $cartId) {
          ...cartDetails
        }
      }
    }
  }
  ${Cart.fragments.cartDetails}
`;

export const CART_STATUS_QUERY = gql`
  query CartStatus($orgId: String!, $cartId: String!) {
    organization(revv_uid: $orgId) {
      revv_uid
      win_red_store
      win_red_store_slug
      slug
      store {
        revv_uid
        cart(revv_uid: $cartId) {
          revv_uid
          status
        }
      }
    }
  }
`;

export const GET_STORE = gql`
  query getStore($orgId: String!) {
    organization(revv_uid: $orgId) {
      revv_uid
      win_red_store
      win_red_store_slug
      slug
      store {
        id
        revv_uid
        settings {
          title
          disclaimer
          long_disclaimer
          maximum_contribution
          logo_url
          favicon_url
          og_title
          og_description
          og_video
          twitter_title
          twitter_description
          open_graph_image_url
          twitter_image_url
          checkout_url
          win_red_checkout_url
          background_color
          primary_color
          secondary_color
          footer_text_color
          footer_background_color
          win_red_disclaimer
          win_red_long_disclaimer
          terms_of_use_url
          privacy_policy_url
          about_our_ads_url
        }
        shipping_rates {
          shipping_cost
          min_total
          max_total
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($orgId: String!, $productSlug: String!) {
    organization(revv_uid: $orgId) {
      revv_uid
      win_red_store
      win_red_store_slug
      slug
      store {
        id
        revv_uid
        store_item(product_slug: $productSlug) {
          product {
            ...productDetails
          }
        }
      }
    }
  }
  ${StoreItem.fragments.productDetails}
`;
