import React, { Component } from "react";
import PropTypes from "prop-types";
import { variantType, optionType } from "../helpers/propTypes";

class VariantSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptionValues: {},
    };
    this.selectOption = this.selectOption.bind(this);
  }

  getUniqueValues(array) {
    // note: make sure this is backwards-compatible with older browsers
    const upperCased = array.map(
      (val) => val.slice(0, 1).toUpperCase() + val.slice(1)
    );
    return upperCased.filter(
      (value, index, self) => self.indexOf(value) === index
    );
  }

  checkSelections() {
    const { selectedOptionValues } = this.state;
    const { options, variants } = this.props;

    // check if the number of selected options is the same as the number of total options
    if (Object.keys(selectedOptionValues).length === options.length) {
      // check if there's a variant that matches the selected option values
      const selectedValues = Object.values(selectedOptionValues).map((value) =>
        value.toLowerCase()
      );
      let matchingVariant = null;

      variants.forEach((variant) => {
        const variantValues = variant.options.map((option) =>
          option.value.toLowerCase()
        );
        let match = true;

        if (variantValues.length !== selectedValues.length) match = false; // can happen if one variant has an option that the others don't have
        if (!variantValues || !selectedValues) match = false;

        variantValues.sort();
        selectedValues.sort();
        
        for (var i = 0; i < variantValues.length; ++i) {
          if (variantValues[i] !== selectedValues[i]) {
            match = false;
          }
        }

        if (match) {
          matchingVariant = variant;
        }
      });

      if (matchingVariant) {
        this.props.setVariant(matchingVariant);
      } else {
        // no variant exists with that combination of values
        this.props.setVariant(null);
      }
    }
  }

  selectOption(option, selectedValue) {
    const { selectedOptionValues } = this.state;
    let updatedValues = selectedOptionValues;

    if (selectedValue === "") {
      delete updatedValues[option.name];
    } else {
      updatedValues = { ...selectedOptionValues, [option.name]: selectedValue };
    }

    this.setState(
      {
        selectedOptionValues: updatedValues,
      },
      this.checkSelections
    );
  }

  renderOptionSelector(option) {
    const { primaryColor } = this.props;
    const values = option.option_values.map((value) => value.value);
    return (
      <select
        style={{ backgroundColor: primaryColor }}
        className="custom-select mb-2"
        key={option.name}
        onChange={(e) => this.selectOption(option, e.target.value)}
      >
        <option value={""}>Select {option.name}</option>
        {this.getUniqueValues(values).map((text, i) => (
          <option key={i}>{text}</option>
        ))}
      </select>
    );
  }

  render() {
    const { options } = this.props;
    return (
      <div className="variant-selector">
        {options.map((option) => this.renderOptionSelector(option))}
      </div>
    );
  }
}

VariantSelector.propTypes = {
  variants: PropTypes.arrayOf(variantType).isRequired,
  options: PropTypes.arrayOf(optionType).isRequired,
  setVariant: PropTypes.func.isRequired,
};

export default VariantSelector;
